<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" offset-lg="6" lg="3" class="d-flex justify-end">
                <v-dialog v-model="dialogPayout" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block large rounded v-bind="attrs" v-on="on">New payout</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2 d-flex justify-center">Payout</v-card-title>
                        <v-card-text class="mt-5">
                            <v-select solo rounded clearable dense v-model="payout.operator" label="Operator"
                                :items="operators">
                            </v-select>
                            <v-text-field solo rounded clearable dense v-model="payout.customerNumber"
                                label="Customer number"></v-text-field>
                            <v-text-field solo rounded clearable dense v-model="payout.amount" label="Amount">
                            </v-text-field>
                            <v-select :items="currencies" v-model="payout.currency" solo rounded clearable dense
                                label="Currency"></v-select>
                            <v-text-field solo rounded clearable dense v-model="payout.reference" label="Reference">
                            </v-text-field>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="success" @click="makePayout" :loading="loadingPayout">
                                Send
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogPayout = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex justify-end">
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block large rounded v-bind="attrs" v-on="on">New Transfert</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2 d-flex justify-center">Transferts</v-card-title>
                        <v-card-text class="mt-5">
                            <v-select solo rounded clearable v-model="transfert.marchand" dense :items="merchants"
                                label="Institution Name">
                            </v-select>
                            <v-select solo rounded clearable :items="channels" v-model="channel" dense label="Channels">
                            </v-select>
                            <v-select solo rounded clearable v-model="transfert.source" v-show="fromBank"
                                :items="fromChannel" dense label="From">
                            </v-select>
                            <v-select solo rounded clearable v-model="transfert.target" v-show="!isBank"
                                :items="toChannel" dense label="To">
                            </v-select>
                            <v-select solo rounded clearable v-model="transfert.devise" :items="currencies" dense
                                label="Currency">
                            </v-select>
                            <v-text-field type="number" solo rounded v-model="transfert.amount" clearable dense
                                label="Amount">
                            </v-text-field>
                            <v-file-input v-model="file" v-show="isBank" truncate-length="15" solo rounded clearable
                                dense label="Upload Payment Document" accept="image/*,.pdf,.doc, .docx"></v-file-input>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="success" @click="makeTransfert">
                                Send
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialog = false">cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs center-active v-model="tab" background-color="transparent" color="basil" grow>
                    <v-tab>PAYOUTS </v-tab>
                    <v-tab>TRANSFERTS</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row class="mx-auto">
            <v-col cols="12">
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table :headers="headersPayouts" :loading="loadingPayoutTable" :items="payouts"
                            class="mt-5" :search="searchPayout">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="searchPayout" label="Search" clearable
                                    class="mx-4 pt-4" append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table :headers="headersTransferts" :loading="loadingTransfertTable" :items="transferts"
                            class="mt-5" :search="searchTransfert">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="searchTransfert" label="Search" clearable
                                    class="mx-4 pt-4" append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-snackbar top v-model="snackbar">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import services from "../services/services"


export default {
    data: () => ({
        tab: null,
        dialog: false,
        file: null,
        loadedFile: null,
        dialogPayout: false,
        loadingPayout: false,
        isBank: false,
        fromBank: true,
        loadingPayoutTable: false,
        loadingTransfertTable: false,
        text: "",
        snackbar: false,
        transferts: [],
        searchPayout: "",
        searchTransfert: "",
        channel: "",
        payout: { operator: "", customerNumber: "", amount: "", reference: "", currency: "" },
        transfert: {
            channel: "",
            source: "",
            target: "",
            amount: 0,
            marchand: "",
            devise: ""
        },
        headersTransferts: [
            { text: "Merchant Code", value: "merchant_code", align: "center" },
            { text: "Channel", value: "channel", align: "center" },
            { text: "Amount", value: "amount", align: "center" },
            { text: "Currency", value: "currency", align: "center" },
            { text: "Transfert Date", value: "transfert_date", align: "center" },
            { text: "From Account", value: "from", align: "center" },
            { text: "To Channel", value: "to", align: "center" },
        ],
        headersPayouts: [
            { text: "Transaction ID", value: "transaction_id", align: "center" },
            { text: "Transaction Reference", value: "reference", align: "center" },
            { text: "Amount", value: "amount", align: "center" },
            { text: "Currency", value: "currency", filterable: false, align: "center" },
            { text: "Operator", value: "method", align: "center" },
            { text: "Customer Details", value: "customer_details", align: "center" },
            { text: "PayDRC Reference", value: "paydrc_reference", align: "center" },
            { text: "Switch Reference", value: "switch_reference", align: "center" },
            { text: "Telco Reference", value: "telco_reference", align: "center" },
            { text: "Created At", value: "created_at", filterable: false, align: "center" },
            { text: "Updated At", value: "updated_at", filterable: false, align: "center" },
            { text: "Action", value: "action", filterable: false, align: "center" },
            { text: "Status", value: "status", filterable: false, align: "center" },
            { text: "Status Description", value: "status_description", filterable: false, align: "center" },
        ],
        currencies: ["USD", "CDF"],
        channels: [
            "Wallet To Bank",
            "Wallet To Wallet",
            "Bank To Wallet"
        ],
        operators: [
            "orange",
            "vodacom",
            "airtel"
        ],
        merchants: [],
        payouts: [],
        fromChannel: ["Vodacom", "Airtel", "Orange"],
        toChannel: ["Vodacom", "Airtel", "Orange"]
    }),
    methods: {
        async makePayout() {

            if (this.payout.operator !== "" && this.payout.customerNumber !== "" && this.payout.amount !== "" && this.payout.reference !== "" && this.payout.currency !== "") {
                this.loadingPayout = true
                let response = await services.makePayout(this.payout)
                this.payout.operator = ""
                this.payout.customerNumber = ""
                this.payout.amount = ""
                this.payout.reference = ""
                this.payout.currency = ""
                this.loadingPayout = false

                if (response === true) {
                    this.text = "Payout make successfully"
                    this.snackbar = true
                }
                else {
                    this.text = "Failed to make payout"
                    this.snackbar = true
                }

                this.loadingPayoutTable = true
                this.payouts = await services.get_fin_payouts()
                this.loadingPayoutTable = false
            }
            else {
                this.text = "Please fill all the fields to send payment"
                this.snackbar = true
            }

        },
        async makeTransfert() {

            if (!["Wallet To Bank"].includes(this.channel)) {
                this.transfert.channel = this.channel
                console.log(this.transfert)

                const response = await services.add_transfert(this.transfert)

                if (response.success === true) {
                    this.text = "Transferts added with success"
                    this.snackbar = true
                }
                else {
                    this.text = "Transferts not added"
                    this.snackbar = true
                }
                this.transfert.channel = ""
                this.transfert.source = ""
                this.transfert.target = ""
                this.transfert.amount = 0
                this.transfert.marchand = ""
                this.transfert.devise = ""
                this.dialog = false
            }
            else {
                if (!this.file) { console.log("No File Chosen") }
                const reader = new FileReader();

                reader.readAsText(this.file);
                reader.onload = async () => {
                    this.loadedFile = reader.result;
                    console.log(this.loadedFile)
                    await services.upload_file(this.loadedFile)
                    this.dialog = false
                }
            }

        }
    },
    watch: {
        channel: function (val) {
            if (val === "Wallet To Bank") {
                this.isBank = true
            }
            else if (val === "Bank To Wallet") {
                this.fromBank = false
                this.isBank = false
            }
            else {
                this.fromBank = true
                this.isBank = false
            }
        },
    },
    async mounted() {
        this.loadingPayoutTable = true
        this.loadingTransfertTable = true
        const temp = await services.get_institution_merchants()

        for (let i = 0; i < temp.data.length; i++) {
            const element = temp.data[i];
            this.merchants.push(element[0])
        }

        this.merchants.sort()

        this.transferts = await services.get_transferts()
        this.payouts = await services.get_fin_payouts()
        this.loadingTransfertTable = false
        this.loadingPayoutTable = false
    },
}
</script>


<style scoped>
</style>